import $ from 'jquery';
import Swiper from 'swiper';
$(document).ready(function () {
  ImageSliderWidget();
});

function ImageSliderWidget() {
  $('.js-slider-widget').each(function (index, element) {
    const slider = element.querySelector('.swiper');
    // eslint-disable-next-line no-unused-vars
    const imageSwiper = new Swiper(slider, {
      centeredSlides: true,
      loop: true,
      spaceBetween: 0,
      slidesPerView: 1,
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
      },
      on: {
        click() {
          this.activeIndex < this.clickedIndex
            ? imageSwiper.slideNext()
            : imageSwiper.slidePrev();
        },
      },
    });
  });
}
