import $ from 'jquery';
import Swiper from 'swiper';
$(document).ready(function () {
  ImageSliderWidget();
});

function ImageSliderWidget() {
  $('.js-card-slider-widget').each(function (index, element) {
    const slider = element.querySelector('.swiper');
    const cardSwiper = new Swiper(slider, {
      centeredSlides: true,
      loop: true,
      spaceBetween: 10,
      slidesPerView: 1,
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1025: {
          slidesPerView: 3,
        },
      },
      on: {
        click() {
          this.activeIndex < this.clickedIndex
            ? cardSwiper.slideNext()
            : cardSwiper.slidePrev();
        },
      },
    });
  });
}
